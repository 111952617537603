<template>
  <!-- border: 1px solid #dadbca -->
  <div>
    <Loading :overlayValue="loading" />
    <v-card
      style="border: 1px solid #dadbca;background: #fff"
      flat
      class="pa-3"
    >
      <v-card-title class="d-flex justify-space-between">
        <div>{{ $t('changePassword') }}</div>
        <v-btn
          outlined
          color="primary"
          height="50"
          class="body-1"
          @click="logoutAction"
          >{{ $t('logout') }}</v-btn
        >
      </v-card-title>
      <v-divider class="my-3" />
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('currentPassword')"
                required
                v-model="currentPassword"
                outlined
                type="password"
                :rules="[(v) => !!v || 'field is required']"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('newPassword')"
                required
                v-model="newPassword"
                outlined
                type="password"
                :rules="[(v) => !!v || 'field is required']"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('confirmPassword')"
                required
                v-model="confirmPassword"
                outlined
                type="password"
                :rules="confirmPasswordRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex flex-column  align-center" v-if="wrongPassword">
          <v-alert
            v-if="true"
            type="error"
            class="body-1 text-center"
            outlined
            style="width: 60%"
            >{{ $t('wrongPassword') }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          style="minWidth: 120px"
          height="50"
          :disabled="loading"
          @click="submit"
          outlined
          class="body-1"
        >
          {{ $t('save') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    Loading: () => import('@/components/Loading.vue'),
  },
  data() {
    return {
      valid: false,
      loading: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      wrongPassword: false,
      confirmPasswordRules: [
        (v) => !!v || 'Confirm Password is required',
        (v) => v === this.newPassword || 'password must be matched',
      ],
    };
  },
  methods: {
    ...mapActions(['logoutAction']),
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          this.wrongPassword = false;
          const payload = {
            email: this.userGetter.currentUser.email,
            password: this.currentPassword,
          };

          const { data } = await ApiService.post('auth/authenticate', payload);
          if (data.data.token) {
            console.log('change pass');
            await ApiService.update('users', this.userGetter.currentUser.id, {
              password: this.newPassword,
            });

            window.location.reload();
          } else {
            console.log('wrongs pass');
            this.wrongPassword = true;
          }
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.wrongPassword = true;
      }
    },
  },
  computed: {
    ...mapGetters(['userGetter']),
  },
};
</script>

<style></style>
